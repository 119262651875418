<template>
  <div id="app" class="max-h-screen">
    <transition>
      <div class="flex justify-evenly" v-if="step == 0">
        <div class="w-2/3 h-screen overflow-hidden overflow-y-hidden bg-cover md:block hidden" style="background-position:right;background-image:url('https://chery2.my-silver.ru/theme/car/images/credit-bg.jpg')"></div>
        <div class="px-4 md:h-auto h-screen md:w-1/2 flex flex-col justify-between bg-white shadow-2xl border-l-4">
          <div>
            <div class="flex md:justify-between justify-center p-6">
              <img src="https://chery2.my-silver.ru/theme/car/images/logo.svg"/>
              <span class="md:block hidden">Официальный дилер CHERY в Магнитогорске </span>
            </div>
            <hr/>
          </div>
          <div class="text-start">
            <h1 class="text-xl font-bold mb-6 md:px-0 px-4">Мощная экономия до 1 199 000 ₽ на новый CHERY в «Сильвер-Авто»</h1>
            <ul class="md:px-0 px-4">
              <li> - Выгодный trade-in</li>
              <li> - Доступен тест-драйв</li>
              <li> - Кредит от 9,9%</li>
            </ul>
            <p class="mt-6 text-sm md:px-0 px-4">Ответьте на 4 вопроса, и получите информацию по наличию и ценам</p>
            <button class="animate-bounce  bg-red-500 p-5 mt-6 text-white font-bold rounded-xl w-96" @click="step = 1">Начать опрос</button>
          </div>
          <div>
            <hr/>
            <div class="flex justify-between p-6">
              <div class="text-start">
                <a class="text-red-500" href="tel:+73522509263">+7 (3519) 54-03-52</a>
                <p class="text-xs">ООО «Сильвер-Авто ПЛЮС» ОГРН 1167456122118 ИНН 7455027496</p>
              </div>
              <p class="text-xs">СИЛЬВЕР</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="flex justify-center mx-auto md:mt-12 w-full" v-if="step == 1">
      <div class="bg-gray-300 md:w-1/2 w-screen md:h-1/2 h-screen border-gray-400 border-2 rounded-2xl p-5">
        <div>
          <h1 class="text-2xl my-6 font-bold text-left">Выберите модель Chery</h1>
          <div class="grid md:grid-cols-3 grid-cols-1 gap-4">
            <div :class="mark === 'Tiggo 8 Pro Max' ? '-translate-y-2 border-red-500' : ''" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white" @click="() => {mark = 'Tiggo 8 Pro Max'}">
              <img class="rounded-t-2xl" src="./assets/img/tigo8promax.jpg"/>
              <p class="p-4 text-start items-center flex justify-between">Tiggo 8 Pro Max <span class="bg-gray-300 p-2 rounded-2xl">→</span></p>
            </div>
            <div :class="mark === 'Tiggo 4 Pro' ? '-translate-y-2 border-red-500' : ''" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white" @click="() => {mark = 'Tiggo 4 Pro'}">
              <img class="rounded-t-2xl"  src="./assets/img/tiggo4pro.jpg"/>
              <p class="p-4 text-start items-center flex justify-between">Tiggo 4 Pro <span class="bg-gray-300 p-2 rounded-2xl">→</span></p>
            </div>
            <div :class="mark === 'Tiggo 7 Pro' ? '-translate-y-2 border-red-500' : ''" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white" @click="() => {mark = 'Tiggo 7 Pro'}">
              <img class="rounded-t-2xl"  src="./assets/img/tiggo7pro.jpg"/>
              <p class="p-4 text-start items-center flex justify-between">Tiggo 7 Pro <span class="bg-gray-300 p-2 rounded-2xl">→</span></p>
            </div>
            <div :class="mark === 'Tiggo 8 Pro' ? '-translate-y-2 border-red-500' : ''" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white" @click="() => {mark = 'Tiggo 8 Pro'}">
              <img class="rounded-t-2xl"  src="./assets/img/tiggo8pro.jpg"/>
              <p class="p-4 text-start items-center flex justify-between">Tiggo 8 Pro <span class="bg-gray-300 p-2 rounded-2xl">→</span></p>
            </div>
            <div :class="mark === 'Tiggo 4' ? '-translate-y-2 border-red-500' : ''" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white" @click="() => {mark = 'Tiggo 4'}">
              <img class="rounded-t-2xl"  src="./assets/img/tiggo4.jpg"/>
              <p class="p-4 text-start items-center flex justify-between">Tiggo 4 <span class="bg-gray-300 p-2 rounded-2xl">→</span></p>
            </div>
            <div :class="mark === 'Другая марка' ? '-translate-y-2 border-red-500' : ''" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white" @click="() => {mark = 'Другая марка'}">
              <img class="rounded-t-2xl"  src="./assets/img/tigo8promax.jpg"/>
              <p class="p-4 text-start items-center flex justify-between">Другая марка <span class="bg-gray-300 p-2 rounded-2xl">→</span></p>
            </div>
          </div>
          <div class="flex justify-between md:inherit sticky bottom-5">
            <button class=" bg-gray-500 p-5 mt-6 text-white font-bold rounded-xl md:w-96" @click="step = 0">Назад</button>
            <button v-if="mark !== ''" class=" bg-red-500 p-5 mt-6 text-white font-bold rounded-xl w-56 md:w-96" @click="step = 2">Далее</button>
            <button v-else class="bg-gray-400 p-5 mt-6 font-bold rounded-xl w-56 md:w-96 text-gray-300">Далее</button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="step == 2">
      <div class="bg-gray-300 md:w-1/2 md:h-1/2 h-screen w-screen border-gray-400 border-2 rounded-2xl p-5">
        <div>
          <h1 class="text-2xl font-bold">Какой тип оплаты вы рассматриваете?</h1>
          <div class="grid md:grid-cols-2 gap-4 mt-6">
            <div :class="payment === '100% оплата' ? '-translate-y-2 border-red-500' : ''"  @click="payment='100% оплата'" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white flex justify-between items-center p-4">
              <p class="text-start">100% оплата</p>
              <span class="bg-gray-300 p-2 rounded-2xl">→</span>
            </div>
            <div :class="payment === 'Свой автомобиль в зачёт' ? '-translate-y-2 border-red-500' : ''" @click="payment='Свой автомобиль в зачёт'" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white flex justify-between items-center p-4">
              <p class="text-start">Свой автомобиль в зачёт</p>
              <span class="bg-gray-300 p-2 rounded-2xl">→</span>
            </div>
            <div :class="payment === 'Лизинг' ? '-translate-y-2 border-red-500' : ''" @click="payment='Лизинг'" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white flex justify-between items-center p-4">
              <p class="text-start">Лизинг</p>
              <span class="bg-gray-300 p-2 rounded-2xl">→</span>
            </div>
            <div :class="payment === 'Кредит по ставке от 9.9%' ? '-translate-y-2 border-red-500' : ''" @click="payment='Кредит по ставке от 9.9%'" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white flex justify-between items-center p-4">
              <p class="text-start">Кредит по ставке от 9.9%</p>
              <span class="bg-gray-300 p-2 rounded-2xl">→</span>
            </div>
          </div>
          <div class="flex justify-between md:inherit sticky bottom-5 ">
            <button class=" bg-gray-500 p-5 mt-6 text-white font-bold rounded-xl md:w-96" @click="step = 1">Назад</button>
            <button v-if="payment !== ''" class=" bg-red-500 p-5 mt-6 text-white font-bold rounded-xl md:w-96 w-56" @click="step = 3">Далее</button>
            <button v-else class="bg-gray-400 p-5 mt-6 font-bold rounded-xl md:w-96 w-56 text-gray-300">Далее</button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="step == 3">
      <div class="bg-gray-300 md:w-1/2 md:h-1/2 h-screen w-screen border-gray-400 border-2 rounded-2xl p-5">
        <div>
          <h1 class="text-2xl font-bold">Хотите пройти тест-драйв?</h1>
          <div class="grid md:grid-cols-2 gap-4 mt-6">
            <div :class="tdrive === 'Да' ? '-translate-y-2 border-red-500' : ''"  @click="tdrive='Да'" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white flex justify-between items-center p-4">
              <p class="text-start">Да</p>
            </div>
            <div :class="tdrive === 'Не нужно' ? '-translate-y-2 border-red-500' : ''" @click="tdrive='Не нужно'" class="hover:-translate-y-2 duration-300 col-span-1 hover:border-red-500 border border-transparent shadow-2xl rounded-2xl bg-white flex justify-between items-center p-4">
              <p class="text-start">Не нужно</p>
            </div>
          </div>
          <div class="flex justify-between md:inherit sticky bottom-5 mt-4">
            <button class=" bg-gray-500 p-5 mt-6 text-white font-bold rounded-xl md:w-96" @click="step = 2">Назад</button>
            <button v-if="tdrive !== ''" class=" bg-red-500 p-5 mt-6 text-white font-bold rounded-xl md:w-96 w-56" @click="step = 4">Далее</button>
            <button v-else class="bg-gray-400 p-5 mt-6 font-bold rounded-xl md:w-96 w-56 text-gray-300">Далее</button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="step == 4">
      <div class="bg-gray-300 md:w-1/2 md:h-1/2 w-screen h-screen border-gray-400 border-2 rounded-2xl p-5">
        <div>
          <h1 class="text-2xl font-bold">Мы уже обновляем данные по наличию и ценам</h1>
          <div class="mt-6">
              <p class="text-sm">Оставьте номер телефона</p>
              <input type="phone" v-model="phone" class="p-4 rounded-3xl font-bold" v-maska="'+7 (###) ### ##-##'" placeholder="+7 (___) ___ __-__">
          </div>
          <div class="flex justify-center">
            <button v-if="phone.length === 18" class=" bg-red-500 p-5 mt-6 text-white font-bold rounded-xl w-96" @click="sendForm">Отправить</button>
            <button v-else class="bg-gray-400 p-5 mt-6 font-bold rounded-xl w-96 text-gray-300">Далее</button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="step == 5">
      <div class="bg-gray-300 md:w-1/2 md:h-1/2 w-screen h-screen border-gray-400 border-2 rounded-2xl p-5">
        <h1 class="text-xl font-bold md:my-0 my-12">Спасибо за то что уделили нам своё время</h1>
        <br/>
        <p class="text-center flex justify-center my-12">
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
        </p>
        <div class="my-12">
          <a href="https://chery2.my-silver.ru/" class=" bg-red-500 p-5 mt-6 text-white font-bold rounded-xl w-full">Наш сайт</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  created () {
    document.title = "Опрос Chery | СИЛЬВЕР";
  },
  data() {
    return {
      step: 0,
      mark: "",
      payment: "",
      tdrive: "",
      phone: ""
    }
  },
  methods: {
    sendForm: function(){

      let me = this;

      let password = '2ksupercheburek';

      let template = `<table>
        <thead>
            <th></th>
            <th></th>
        </thead>
        <tbody>
            <tr>
                <td>Формочка опроса</td>
                <td style="padding-left: 1rem">Chery</td>
            </tr>
            <tr>
                <td>Марка автомобиля</td>
                <td style="padding-left: 1rem">${me.mark}</td>
            </tr>
            <tr>
                <td>Оплата</td>
                <td style="padding-left: 1rem">${me.payment}</td>
            </tr>
            <tr>
                <td>Тест-Драйв</td>
                <td style="padding-left: 1rem">${me.tdrive}</td>
            </tr>
            <tr>
                <td>Телефон</td>
                <td style="padding-left: 1rem">${me.phone}</td>
            </tr>
        </tbody>
    </table>`;

      if(this.phone.length > 0 && this.phone.length === 18){
        me.step = 5;

        axios.post('https://dev.my-silver.ru/api/forms/proxy_send', {
          password: password,
          subject: 'Опрос Chery',
          mail: 'leads@my-silver.ru',
          //mail: 'leads@my-silver.ru',
          template: template
        }).then(function(){
          document.location.href = 'https://chery2.my-silver.ru/';
        });
      }
    },
    unmaskedValue: function(){
      var val = this.$refs.input.clean
      console.log(val)
    }
  }
}
</script>

<style scoped src="../public/output.css"></style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: auto;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #7ac142;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 10px #7ac142;
  }
}
</style>
